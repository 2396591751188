import React from "react"
import { Container } from "react-bootstrap"
import Layout from "../components/layout"
import { default as ProjectSection } from "../components/projectSection"

export default () => (
  <Layout>
    <Container class="pt-5">
      <ProjectSection />
    </Container>
  </Layout>
)