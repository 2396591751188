import React from "react"
import { CardGroup, Container, Card, Badge } from "react-bootstrap"

export default ()=> {
  return (
    <Container>
      <CardGroup className="vgr-cards">
        <Card>
          <div class="card-img-body">
          <img class="card-img" src="https://picsum.photos/500/230" alt="Card image cap" />
          </div>
          <Card.Body>
            <Card.Title>Project 1</Card.Title>
              <div className="flex-wrap">
                <Badge pill variant="dark" className="px-2 mr-1">Python</Badge>
                <Badge pill variant="dark" className="px-2 mr-1">C++</Badge>
                <Badge pill variant="dark" className="px-2 mr-1">.NET Core</Badge>
                <Badge pill variant="dark" className="px-2 mr-1">Ruby On Rails</Badge>
                <Badge pill variant="dark" className="px-2 mr-1">Haskell</Badge>
              </div>
            <Card.Text>This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</Card.Text>
            <a href="#" class="btn btn-outline-primary">View source</a>
          </Card.Body>
        </Card>
        <Card>
          <div class="card-img-body">
            <img class="card-img" src="https://picsum.photos/400/200" alt="Card image cap" />
          </div>
          <Card.Body>
            <Card.Title>Project 2</Card.Title>
            <div className="flex-wrap">
                <Badge pill variant="dark" className="px-2 mr-1">Python</Badge>
                <Badge pill variant="dark" className="px-2 mr-1">C++</Badge>
                <Badge pill variant="dark" className="px-2 mr-1">.NET Core</Badge>
                <Badge pill variant="dark" className="px-2 mr-1">Ruby On Rails</Badge>
                <Badge pill variant="dark" className="px-2 mr-1">Haskell</Badge>
              </div>
            <Card.Text>This card has supporting text below as a natural lead-in to additional content. This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer. This card has supporting text below as a natural lead-in to additional content.</Card.Text>
            <a href="#" class="btn btn-outline-primary">View source</a>
          </Card.Body>
        </Card>
        <Card>
          <div class="card-img-body">
            <img class="card-img" src="https://picsum.photos/450/250" alt="Card image cap" />
          </div>
          <Card.Body>
            <Card.Title>Project 3</Card.Title>
            <div className="flex-wrap">
                <Badge pill variant="dark" className="px-2 mr-1">Python</Badge>
                <Badge pill variant="dark" className="px-2 mr-1">C++</Badge>
                <Badge pill variant="dark" className="px-2 mr-1">.NET Core</Badge>
                <Badge pill variant="dark" className="px-2 mr-1">Ruby On Rails</Badge>
                <Badge pill variant="dark" className="px-2 mr-1">Haskell</Badge>
              </div>
            <Card.Text>This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action. This is a wider card with supporting text below as a natural lead-in
              to additional content. This content is a little bit longer.</Card.Text>
            <a href="#" class="btn btn-outline-primary">View source</a>
          </Card.Body>
        </Card>
      </CardGroup>
    </Container>
  )
}

{/* // export default ()=> {
//     return (
//         <Row className="flex-wrap justify-content-start">
//         <Card className="p-2">
//           <Card.Title className="p-auto">
//           <h1>Project</h1>
//           </Card.Title>
//           <Card.Subtitle className="p-auto">
//             <div className="flex-wrap">
//             <Badge pill variant="dark" className="px-2 mr-1">Python</Badge>
//             <Badge pill variant="dark" className="px-2 mr-1">C++</Badge>
//             <Badge pill variant="dark" className="px-2 mr-1">.NET Core</Badge>
//             <Badge pill variant="dark" className="px-2 mr-1">Ruby On Rails</Badge>
//             <Badge pill variant="dark" className="px-2 mr-1">Haskell</Badge>
//             </div>
//           </Card.Subtitle>
//           <Card.Body className="p-auto">
//             <Jumbotron>Here is some description text!</Jumbotron>
//           </Card.Body>
//         </Card>
//         <p className="my-auto ml-5"><Button variant="primary">View source</Button></p>
//       </Row>
//     )
// } */}